/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function property(profile, property) {
  if (!profile) return null;

  switch (property.toString().toUpperCase())
  {
    case 'NAME': return profile.name ?? profile.id ?? profile.uid ?? profile.type;
    case 'EXCERPT': return profile.c_excerpt ?? profile.c_rawContent;
    case 'URL': return profile.websiteUrl?.url ?? profile.website ?? profile.d_link;
    case 'AUTHOR': return profile.c_authorName;
    case 'CATEGORIES': return profile.c_dailyCitizenCategories;
    case 'IMAGE': return profile.c_image;
    case 'DATE': return profile.c_date;
  }
}

export function date(input) {
   if (!input) return null;

    try {
      const date = new Date(input);

      return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
    } catch {
      console.log();
      return null;
    }
}

export function imageWithFallback(image) {
  if (image) {
    return Formatter.image(image, "800x1", true).url;
  } else {
    return "/static/assets/images/logo.webp";
  }
}

export function text(input, wordLimit) {
  if (!input) return "";
  if (!wordLimit) return input ?? "";

  let result = "";
  let words = input?.split(" ") ?? [];
  for (let i = 0; i < Math.min(words.length, wordLimit); i++) {
    result += words[i] + " ";
    if (i >= wordLimit - 1) {
      result += " ...";
    }
  }

  return result;
}